import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

/* This is needed because chakra is buggy and the docs untruthful */
const buttonFont = {
  fontSize: "0.8125rem",
  lineHeight: "0.975rem",
  fontWeight: 600,
  whiteSpace: "nowrap",
  letterSpacing: "0",
};

const conversationalFont = {
  fontSize: "0.8125rem",
  lineHeight: "0.975rem",
  fontWeight: 400,
  whiteSpace: "nowrap",
  letterSpacing: "0.01",
};

/* Variants */
const primary = defineStyle({
  ...buttonFont,
  height: "2rem",
  maxH: "2rem",
  minW: "unset",
  padding: "0.5rem 0.75rem",
  color: "blue.50",
  backgroundColor: "blue.800",
  boxSizing: "border-box",
  _hover: {
    backgroundColor: "blue.700",
    _disabled: { backgroundColor: "gray.400", cursor: "not-allowed" },
  },
  _active: {
    backgroundColor: "blue.900",
  },
  _disabled: {
    backgroundColor: "gray.400",
    color: "gray.100",
    cursor: "not-allowed",
  },
});

const secondary = defineStyle({
  ...buttonFont,
  height: "2rem",
  maxH: "2rem",
  minW: "unset",
  padding: "0.5rem 0.75rem",
  color: "blue.800",
  backgroundColor: "gray.0",
  border: "1px solid",
  borderColor: "blue.800",
  boxSizing: "border-box",
  _hover: {
    backgroundColor: "blue.50",
    borderColor: "blue.700",
    _disabled: { backgroundColor: "gray.100", cursor: "not-allowed" },
  },
  _active: {
    backgroundColor: "blue.100",
    color: "blue.900",
    borderColor: "blue.900",
  },
  _disabled: {
    backgroundColor: "gray.100",
    color: "gray.400",
    borderColor: "gray.500",
    cursor: "not-allowed",
  },
});

const tertiary = defineStyle({
  ...buttonFont,
  height: "2rem",
  maxH: "2rem",
  minW: "unset",
  padding: "0.5rem 0.75rem",
  color: "blue.800",
  backgroundColor: "gray.0",
  boxSizing: "border-box",
  _hover: {
    backgroundColor: "blue.50",
    _disabled: { backgroundColor: "gray.0", cursor: "not-allowed" },
  },
  _active: {
    backgroundColor: "blue.100",
    color: "blue.900",
    _disabled: { color: "gray.500" },
  },
  _disabled: {
    color: "gray.500",
    cursor: "not-allowed",
  },
});

const primaryWithIcon = defineStyle({
  ...primary,
  padding: "0.375rem 0.75rem 0.375rem 0.5rem",
  boxSizing: "border-box",
  "& .chakra-button__icon": {
    marginRight: "0.25rem",
  },
});

const secondaryWithIcon = defineStyle({
  ...secondary,
  padding: "0.375rem 0.75rem 0.375rem 0.5rem",
  "& .chakra-button__icon": {
    marginRight: "0.25rem",
  },
});

const tertiaryWithIcon = defineStyle({
  ...tertiary,
  padding: "0.375rem 0.75rem 0.375rem 0.5rem",
  "& .chakra-button__icon": {
    marginRight: "0.25rem",
  },
});

const primaryWithRightIcon = defineStyle({
  ...primary,
  padding: "0.375rem 0.5rem 0.375rem 0.75rem",
  "& .chakra-button__icon": {
    marginLeft: "0.25rem",
  },
});

const secondaryWithRightIcon = defineStyle({
  ...secondary,
  padding: "0.375rem 0.5rem 0.375rem 0.75rem",
  "& .chakra-button__icon": {
    marginLeft: "0.25rem",
  },
});

const tertiaryWithRightIcon = defineStyle({
  ...tertiary,
  padding: "0.375rem 0.5rem 0.375rem 0.75rem",
  "& .chakra-button__icon": {
    marginLeft: "0.25rem",
  },
});
/* Danger */

const dangerPrimary = defineStyle({
  ...buttonFont,
  height: "2rem",
  maxH: "2rem",
  minW: "unset",
  padding: "0.5rem 0.75rem",
  color: "gray.0",
  backgroundColor: "red.700",
  _hover: { backgroundColor: "red.600" },
  _active: {
    backgroundColor: "red.800",
  },
});

const dangerTertiary = defineStyle({
  ...buttonFont,
  height: "2rem",
  maxH: "2rem",
  minW: "unset",
  padding: "0.5rem 0.75rem",
  backgroundColor: "gray.0",
  color: "red.700",
  _hover: { color: "red.600", backgroundColor: "red.100" },
  _active: {
    color: "red.800",
    backgroundColor: "red.200",
  },
});

const dangerPrimaryWithIcon = defineStyle({
  ...dangerPrimary,
  padding: "0.375rem 0.75rem 0.375rem 0.5rem",
  gap: "0.25rem",
  "& .chakra-button__icon": {
    marginRight: "0.25rem",
  },
});

const dangerTertiaryWithIcon = defineStyle({
  ...dangerTertiary,
  padding: "0.375rem 0.75rem 0.375rem 0.5rem",
  gap: "0.25rem",
  "& .chakra-button__icon": {
    marginRight: "0.25rem",
  },
});

/* Conversational */
const conversational = defineStyle({
  ...conversationalFont,
  height: "2rem",
  minW: "unset",
  textStyle: "input",
  padding: "0.5rem 0.75rem",
  color: "gray.500",
  backgroundColor: "gray.0",
  border: "1px solid",
  borderColor: "gray.75",
  borderRadius: "0.5rem",
  _disabled: {
    backgroundColor: "gray.50",
    color: "gray.200",
    borderColor: "gray.100",
    cursor: "not-allowed",
  },
  _hover: {
    color: "gray.700",
    borderColor: "gray.100",
    _disabled: {
      backgroundColor: "gray.50",
      color: "gray.200",
      borderColor: "gray.100",
      cursor: "not-allowed",
    },
  },
  _active: {
    borderColor: "blue.100",
    backgroundColor: "blue.100",
    color: "blue.800",
    _disabled: {
      backgroundColor: "gray.50",
      color: "gray.200",
      borderColor: "gray.100",
      cursor: "not-allowed",
    },
  },
});

const conversationalWithLeftIcon = defineStyle({
  ...conversational,
  padding: "0.375rem 0.75rem 0.375rem 0.5rem",
  "& .chakra-button__icon": {
    marginRight: "0.25rem",
  },
});

const conversationalWithRightIcon = defineStyle({
  ...conversational,
  padding: "0.375rem 0.5rem 0.375rem 0.75rem",
  "& .chakra-button__icon": {
    marginLeft: "0.25rem",
  },
});

/* Icon Buttons */
/* Even though there is an IconButton component, I can't find a way to style it */
const iconPrimary = defineStyle({
  height: "fit-content",
  minW: "unset",
  padding: "0.375rem",
  px: "0.375rem",
  py: "0.375rem",
  color: "blue.800",
  backgroundColor: "gray.0",
  border: "1px solid",
  borderColor: "blue.800",
  _hover: {
    backgroundColor: "blue.50",
    borderColor: "blue.700",
    _disabled: {
      backgroundColor: "gray.100",
      color: "gray.300",
      borderColor: "gray.500",
      cursor: "not-allowed",
    },
  },
  _active: {
    backgroundColor: "blue.100",
    color: "blue.900",
    borderColor: "blue.900",
    _disabled: {
      backgroundColor: "gray.100",
      color: "gray.300",
      borderColor: "gray.500",
      cursor: "not-allowed",
    },
  },
  _disabled: {
    backgroundColor: "gray.100",
    color: "gray.300",
    borderColor: "gray.500",
    cursor: "not-allowed",
  },
});

const iconSecondary = defineStyle({
  height: "fit-content",
  minW: "unset",
  padding: "0.375rem",
  paddingBottom: "0.375rem",
  px: "0.375rem",
  py: "0.375rem",
  color: "blue.800",
  backgroundColor: "transparent",
  _hover: {
    backgroundColor: "blue.50",
    _disabled: {
      color: "gray.200",
      cursor: "not-allowed",
    },
  },
  _active: {
    backgroundColor: "blue.100",
    color: "blue.900",
    _disabled: {
      color: "gray.200",
      cursor: "not-allowed",
    },
  },
  _disabled: {
    color: "gray.200",
    cursor: "not-allowed",
  },
});

const iconTertiary = defineStyle({
  height: "fit-content",
  minW: "unset",
  fontSize: "1rem",
  lineHeight: "1rem",
  padding: "0.125rem",
  color: "gray.400",
  backgroundColor: "transparent",
  _hover: {
    color: "blue.800",
    backgroundColor: "blue.50",
    _disabled: {
      cursor: "not-allowed",
      color: "gray.200",
    },
  },
  _active: {
    backgroundColor: "blue.100",
    color: "blue.950",
    _disabled: {
      cursor: "not-allowed",
      color: "gray.200",
    },
  },
  _disabled: {
    cursor: "not-allowed",
    color: "gray.200",
  },
});

const iconConversationalPrimary = defineStyle({
  ...conversational,
  padding: "0.375rem",
});

const iconConversationalSecondary = defineStyle({
  ...conversationalFont,
  height: "2rem",
  minW: "unset",
  padding: "0.375rem",
  color: "blue.50",
  backgroundColor: "blue.800",
  gap: "0.25rem",
  _disabled: {
    border: "1px solid",
    borderColor: "gray.100",
    backgroundColor: "gray.50",
    color: "gray.300",
    _hover: { backgroundColor: "gray.50" },
  },
  _hover: {
    backgroundColor: "blue.700",
  },
  _active: {
    backgroundColor: "blue.900",
  },
});

/* No background */

const simpleBlue = defineStyle({
  ...buttonFont,
  height: "fit-content",
  minW: "unset",
  padding: "0",
  color: "blue.600",
  backgroundColor: "transparent",
  _hover: {
    color: "blue.950",
    _disabled: { cursor: "not-allowed" },
  },
  _active: {
    color: "blue.950",
    _disabled: { color: "gray.500" },
  },
  _disabled: {
    color: "gray.500",
    cursor: "not-allowed",
  },
});

const simpleGray = defineStyle({
  ...buttonFont,
  height: "fit-content",
  minW: "unset",
  padding: "0",
  color: "gray.500",
  backgroundColor: "transparent",
  _hover: {
    color: "gray.700",
    _disabled: { cursor: "not-allowed" },
  },
  _active: {
    color: "gray.700",
    _disabled: { color: "gray.500" },
  },
  _disabled: {
    color: "gray.500",
    cursor: "not-allowed",
  },
});

const simpleDanger = defineStyle({
  ...buttonFont,
  height: "fit-content",
  minW: "unset",
  padding: "0",
  color: "red.700",
  backgroundColor: "transparent",
  _hover: {
    color: "red.900",
    _disabled: { cursor: "not-allowed" },
  },
  _active: {
    color: "red.900",
    _disabled: { color: "gray.500" },
  },
  _disabled: {
    color: "gray.500",
    cursor: "not-allowed",
  },
});

const simpleWarning = defineStyle({
  ...buttonFont,
  height: "fit-content",
  minW: "unset",
  padding: "0",
  color: "yellow.700",
  backgroundColor: "transparent",
  _hover: {
    color: "yellow.900",
    _disabled: { cursor: "not-allowed" },
  },
  _active: {
    color: "yellow.900",
    _disabled: { color: "gray.500" },
  },
  _disabled: {
    color: "gray.500",
    cursor: "not-allowed",
  },
});

const simpleSuccess = defineStyle({
  ...buttonFont,
  height: "fit-content",
  minW: "unset",
  padding: "0",
  color: "green.700",
  backgroundColor: "transparent",
  _hover: {
    color: "green.900",
    _disabled: { cursor: "not-allowed" },
  },
  _active: {
    color: "green.900",
    _disabled: { color: "gray.500" },
  },
  _disabled: {
    color: "gray.500",
    cursor: "not-allowed",
  },
});

/* Other */

const chipButton = defineStyle({
  height: "fit-content",
  fontSize: "0.8125rem",
  lineHeight: "1.375rem",
  fontWeight: 400,
  whiteSpace: "nowrap",
  letterSpacing: "0.01rem",
  padding: "0.25rem 0.5rem",
  width: "100%",
  backgroundColor: "unset",
  color: "blue.950",
  textOverflow: "hidden",
  justifyContent: "flex-start",
  _hover: {
    backgroundColor: "blue.50",
  },
  _active: {
    backgroundColor: "blue.100",
  },
  _disabled: {
    color: "gray.500",
  },
  "& .chakra-button__icon": {
    marginRight: "0.25rem",
  },
});

const resource = defineStyle({
  boxSizing: "border-box",
  height: "fit-content",
  width: "fit-content",
  minWidth: "fit-content",
  backgroundColor: "gray.0",
  border: "1px solid",
  borderColor: "gray.75",
  borderRadius: "0.75rem",
  color: "gray.600",
  padding: "0.5rem 0.75rem",
  textAlign: "start",
  justifyContent: "flex-start",
  fontSize: "0.8125rem",
  lineHeight: "1.375rem",
  fontWeight: 400,
  whiteSpace: "pre-line",
  letterSpacing: "0.01rem",
  "& .chakra-button__icon": {
    marginRight: "0.5rem",
  },
  _hover: {
    backgroundColor: "blue.50",
    borderColor: "blue.200",
    _disabled: {
      cursor: "not-allowed",
    },
  },
  _disabled: {
    color: "gray.400",
    borderColor: "gray.100",
  },
});

export const buttonTheme = defineStyleConfig({
  baseStyle: defineStyle({
    textStyle: "button",
    boxSizing: "border-box",
    alignItems: "center",
    justifyContent: "center",
    whiteSpace: "nowrap",
    borderRadius: "0.5rem",
    _hover: {
      cursor: "pointer",
    },
    _disabled: {
      cursor: "not-allowed",
    },
  }),
  variants: {
    primary,
    secondary,
    tertiary,
    primaryWithIcon,
    secondaryWithIcon,
    tertiaryWithIcon,
    primaryWithRightIcon,
    secondaryWithRightIcon,
    tertiaryWithRightIcon,
    dangerPrimary,
    dangerTertiary,
    dangerPrimaryWithIcon,
    dangerTertiaryWithIcon,
    conversational,
    conversationalWithLeftIcon,
    conversationalWithRightIcon,
    iconPrimary,
    iconSecondary,
    iconTertiary,
    iconConversationalPrimary,
    iconConversationalSecondary,
    simpleDanger,
    simpleSuccess,
    simpleWarning,
    simpleBlue,
    simpleGray,
    chipButton,
    resource,
  },
  defaultProps: {
    variant: "primary",
  },
});
