import axios from "axios";
import { client } from "@/generated/services.gen";

client.setConfig({
  baseURL: "/api/backend/",
  throwOnError: true,
});

client.instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      window.location.href = "/api/auth/logout";
    }
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      window.location.href = "/api/auth/logout";
    }
    return Promise.reject(error);
  }
);

export default axios;
