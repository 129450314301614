"use client";
import { scan } from "react-scan";
import { useEffect } from "react";

export function ReactScan(): JSX.Element {
  useEffect(() => {
    scan({
      enabled: process.env.NODE_ENV === "development",
    });
  }, []);

  return <></>;
}

export default ReactScan;
