"use client";

import { useUser } from "@auth0/nextjs-auth0/client";
import { useEffect } from "react";
import { initMixpanel } from "./mixpanel";
import { launchSessionReview } from "./sessionrewind";
import { initPylon, setupPylonEditorHideEvent } from "./pylon";
import * as Sentry from "@sentry/nextjs";

export const CustomerToolsInjectorAux = ({
  isEnvReady,
}: {
  isEnvReady: boolean;
}) => {
  const { user } = useUser();

  /* Pylon, Session Rewind and Mixpanel */
  useEffect(() => {
    launchSessionReview(user?.email || "", user?.name || "");
    initMixpanel(user?.email || "");
    initPylon(
      user?.name || user?.email || undefined,
      user?.email || "",
      user?.picture || undefined
    );
  }, [user, isEnvReady]);

  /* Pylon function setup */
  useEffect(() => {
    setupPylonEditorHideEvent();
  }, []);

  useEffect(() => {
    Sentry.setUser({ email: user?.email || "" });
  }, [user]);

  return <></>;
};
