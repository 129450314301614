import { extendTheme, Kbd } from "@chakra-ui/react";
import { checkboxTheme } from "./chakra/Checkbox";
import { buttonTheme } from "./chakra/Button";
import { switchTheme } from "./chakra/Switch";
import { tooltipTheme } from "./chakra/Tooltip";
import { kbdTheme } from "./chakra/Kbd";
import { tabsTheme } from "./chakra/Tabs";

export const fonts = {
  0: ["2.0rem", null, null, null, "2.5rem"],
  1: ["1.75rem", null, null, null, "2.25rem"],
  2: ["1.625rem", null, null, null, "2rem"],
  3: ["1.5rem", null, null, null, "1.875rem"],
  4: ["1.375rem", null, null, null, "1.75rem"],
  5: ["1.25rem", null, null, null, "1.625rem"],
  6: ["1.125rem", null, null, null, "1.5rem"],
  7: ["1.0rem", null, null, null, "1.375rem"],
  8: ["0.875rem", null, null, null, "1.25rem"],
  9: ["0.75rem", null, null, null, "1.125rem"],
  10: ["1rem", null, null, null, null],
  11: ["0.875rem", null, null, null, null],
  12: ["0.75rem", null, null, null, null],
  13: ["0.5rem", null, null, null, null],
};

const theme = {
  breakpoints: {
    base: "0em",
    sm: "30em",
    md: "48em",
    lg: "62em",
    xl: "82em",
    "2xl": "96em",
  },
  colors: {
    /* Main colors */
    blue: {
      50: "#F0F5FA",
      100: "#D8E9F7",
      200: "#CCDEEC",
      300: "#89B3D3",
      400: "#689DC6",
      500: "#4687B9",
      600: "#396E97",
      700: "#2C5676",
      800: "#203D54",
      900: "#132532",
      950: "#060C11",
    },
    beige: {
      50: "#F8F4F0",
      100: "#E6DCD2",
      200: "#D6C5B4",
      300: "#C5AE97",
      400: "#B49779",
      500: "#A4805B",
      600: "#86684A",
      700: "#68513A",
      800: "#4A3A29",
      900: "#2D2319",
      950: "#0F0C08",
    },
    gray: {
      0: "#FFFFFF",
      50: "#F5F5F5",
      75: "#EBEBF0",
      100: "#D5D7DA",
      200: "#C1C4C9",
      300: "#A9ACB3",
      400: "#90959D",
      500: "#717680",
      600: "#62666F",
      700: "#4C4F56",
      800: "#36393E",
      900: "#212225",
      950: "#0B0B0C",
      1000: "#000000",
    },
    /* Color kit */
    green: {
      50: "#EBFAF0",
      100: "#D1FADF",
      200: "#A6F4C5",
      300: "#6CE9A6",
      400: "#32D583",
      500: "#12B76A",
      600: "#039855",
      700: "#027A48",
      800: "#05603A",
      900: "#054F31",
    },
    yellow: {
      50: "#FFF7E0",
      100: "#FEF0C7",
      200: "#FEDF89",
      300: "#FEC84B",
      400: "#FDB022",
      500: "#F79009",
      600: "#DC6803",
      700: "#B54708",
      800: "#93370D",
      900: "#792E0D",
    },
    red: {
      50: "#FEF2F1",
      100: "#FEE4E2",
      200: "#FECDCA",
      300: "#FDA29B",
      400: "#F97066",
      500: "#F04438",
      600: "#D92D20",
      700: "#B42318",
      800: "#912018",
      900: "#7A271A",
    },
    pink: {
      50: "#FCFBFF",
      100: "#FCE7F6",
      200: "#FCCEEE",
      300: "#FAA7E0",
      400: "#F670C7",
      500: "#EE46BC",
      600: "#DD2590",
      700: "#C11574",
      800: "#9E165F",
      900: "#851651",
    },
    purple: {
      50: "#FCFBFF",
      100: "#EBE9FE",
      200: "#D9D6FE",
      300: "#BDB4FE",
      400: "#9B8AFB",
      500: "#7A5AF8",
      600: "#6938EF",
      700: "#5925DC",
      800: "#4A1FB8",
      900: "#3E1C96",
    },
    fblue: {
      50: "#FCFBFF",
      100: "#D1E9FF",
      200: "#B2DDFF",
      300: "#84CAFF",
      400: "#53B1FD",
      500: "#2E90FA",
      600: "#1570EF",
      700: "#175CD3",
      800: "#1849A9",
      900: "#194185",
    },
  },
  shadows: {
    shadow1: "0px 0px 2px 0px rgba(52, 87, 140, 0.1216)",
    shadow2: "0px 4px 8px 0px rgba(52, 87, 140, 0.1216)",
    shadow3: "0px 4px 8px 0px rgba(52, 87, 140, 0.5)",
  },
  textStyles: {
    /* Base */
    baseXS: {
      fontSize: "0.5rem",
      lineHeight: "0.5rem",
      fontWeight: 400,
      whiteSpace: "pre-line",
      letterSpacing: "0.01rem",
    },
    baseS: {
      fontSize: "0.6875rem",
      lineHeight: "1.25rem",
      fontWeight: 400,
      whiteSpace: "pre-line",
      letterSpacing: "0.01rem",
    },
    baseM: {
      fontSize: "0.8125rem",
      lineHeight: "1.375rem",
      fontWeight: 400,
      whiteSpace: "pre-line",
      letterSpacing: "0.01rem",
    },
    baseL: {
      fontSize: "0.9375rem",
      lineHeight: "1.375rem",
      fontWeight: 400,
      whiteSpace: "pre-line",
      letterSpacing: "0.01rem",
    },
    baseXL: {
      fontSize: "1.25rem",
      lineHeight: "1.25rem",
      fontWeight: 400,
      whiteSpace: "pre-line",
      letterSpacing: "0.01rem",
    },
    baseXXL: {
      fontSize: "1.5rem",
      lineHeight: "1.5rem",
      fontWeight: 400,
      whiteSpace: "pre-line",
      letterSpacing: "0",
    },
    baseXXXL: {
      fontSize: "2rem",
      lineHeight: "2.4rem",
      fontWeight: 400,
      whiteSpace: "pre-line",
      letterSpacing: "0.01rem",
    },
    /* Bold */
    boldS: {
      fontSize: "0.6875rem",
      lineHeight: "1.25rem",
      fontWeight: 600,
      whiteSpace: "pre-line",
      letterSpacing: "0.01rem",
    },
    boldM: {
      fontSize: "0.8125rem",
      lineHeight: "1.375rem",
      fontWeight: 600,
      whiteSpace: "pre-line",
      letterSpacing: "0.01rem",
    },
    boldL: {
      fontSize: "0.9375rem",
      lineHeight: "1.375rem",
      fontWeight: 600,
      whiteSpace: "pre-line",
      letterSpacing: "0.01rem",
    },
    boldXL: {
      fontSize: "1.25rem",
      lineHeight: "1.25rem",
      fontWeight: 600,
      whiteSpace: "pre-line",
      letterSpacing: "0",
    },
    boldXXL: {
      fontSize: "1.5rem",
      lineHeight: "1.5rem",
      fontWeight: 600,
      whiteSpace: "pre-line",
      letterSpacing: "0.01rem",
    },
    /* Functional */
    button: {
      fontSize: "0.8125rem",
      lineHeight: "0.975rem",
      fontWeight: 600,
      whiteSpace: "nowrap",
      letterSpacing: "0",
    },
    input: {
      fontSize: "0.8125rem",
      lineHeight: "0.975rem",
      fontWeight: 400,
      whiteSpace: "pre-line",
      letterSpacing: "0.01rem",
    },
    inputHelper: {
      fontSize: "0.6875rem",
      lineHeight: "0.6875rem",
      fontWeight: 400,
      whiteSpace: "pre-line",
      letterSpacing: "0.01rem",
    },
    pageTitle: {
      fontSize: "1.5rem",
      lineHeight: "1.75rem",
      fontWeight: 500,
      whiteSpace: "nowrap",
      letterSpacing: "0",
      textOverflow: "ellipsis",
    },
    conversationalHelper: {
      fontSize: "0.5rem",
      textTransform: "uppercase",
      lineHeight: "0.875rem",
      fontWeight: 600,
      whiteSpace: "nowrap",
      letterSpacing: "0",
    },
  },
  components: {
    Button: buttonTheme,
    Checkbox: checkboxTheme,
    Divider: {
      baseStyle: {
        opacity: "1",
        borderColor: "blue.100",
      },
    },
    Drawer: {
      variants: {
        disabledOverlay: {
          parts: ["dialog, dialogContainer"],
          dialog: {
            pointerEvents: "auto",
          },
          dialogContainer: {
            pointerEvents: "none",
          },
        },
      },
    },
    IconButton: buttonTheme,
    Kbd: kbdTheme,
    Switch: switchTheme,
    Tabs: tabsTheme,
    Tooltip: tooltipTheme,
  },
  styles: {
    global: () => ({
      body: {
        fontFamily: "BDO Grotesk, sans-serif",
      },
    }),
  },
};
export default extendTheme(theme);
