import { switchAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyle = definePartsStyle({
  thumb: {
    height: "1rem",
    width: "1rem",
    display: "flex",
    alignItems: "center",
    margin: "0.125rem",
    _checked: {
      transform: "translateX(1.25rem)",
    },
  },
  track: {
    height: "1.25rem",
    width: "2.5rem",
    padding: "0",
    bg: "gray.300",
    _hover: {
      bg: "gray.500",
    },
    _checked: {
      bg: "blue.500",
      _hover: {
        bg: "blue.700",
      },
    },
    _after: {
      content: "''",
      position: "absolute",
      right: "0.25rem",
      top: "50%",
      transform: "translateY(-50%)",
      backgroundColor: "white",
      webkitMaskImage: "url('/images/svg/x.svg')",
      webkitMaskRepeat: "no-repeat",
      webkitMaskPosition: "center center",
      webkitMaskSize: "100%",
      maskImage: "url('/images/svg/x.svg')",
      maskRepeat: "no-repeat",
      maskPosition: "center center",
      maskSize: "100%",
      height: "0.75rem",
      width: "0.75rem",
    },
    _before: {
      content: "''",
      position: "absolute",
      left: "0.25rem",
      top: "50%",
      transform: "translateY(-50%)",
      backgroundColor: "white",
      webkitMaskImage: "url('/images/svg/check.svg')",
      webkitMaskRepeat: "no-repeat",
      webkitMaskPosition: "center center",
      webkitMaskSize: "100%",
      maskImage: "url('/images/svg/check.svg')",
      maskRepeat: "no-repeat",
      maskPosition: "center center",
      maskSize: "100%",
      height: "0.75rem",
      width: "0.75rem",
    },
  },
});

const sizes = {
  sm: {
    thumb: {
      height: "0.75rem",
      width: "0.75rem",
      _checked: {
        transform: "translateX(1rem)",
      },
    },
    track: {
      height: "1rem",
      width: "2rem",
      _after: {
        height: "0.5rem",
        width: "0.5rem",
      },
      _before: {
        height: "0.5rem",
        width: "0.5rem",
      },
    },
  },
  md: {
    thumb: {
      height: "1rem",
      width: "1rem",
      _checked: {
        transform: "translateX(1.25rem)",
      },
    },
    track: {
      height: "1.25rem",
      width: "2.5rem",
      _after: {
        height: "0.75rem",
        width: "0.75rem",
      },
      _before: {
        height: "0.75rem",
        width: "0.75rem",
      },
    },
  },
};

export const switchTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    size: "md",
  },
});
