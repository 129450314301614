// @ts-nocheck
import { EnvService } from "@/services/Environment/Environment";

export function initPylon(
  name?: string,
  email?: string,
  avatar_url?: string
  //email_hash?: string
) {
  if (!name || !email || !EnvService.getVar("PYLON_ID")) {
    return;
  }

  const settings = avatar_url
    ? {
        app_id: EnvService.getVar("PYLON_ID"),
        email,
        name,
        avatar_url,
        //email_hash,
      }
    : {
        app_id: EnvService.getVar("PYLON_ID"),
        email,
        name,
        // email_hash,
      };

  window.pylon = {
    chat_settings: settings,
  };
}

export function openPylonChat() {
  if (typeof window !== "undefined" && window.Pylon) {
    window.Pylon("show");
  }
}

export function triggerPylonMessage(message?: string) {
  if (typeof window !== "undefined" && window.Pylon) {
    window.Pylon("showNewMessage", message);
  }
}

export function showPylonBubble() {
  if (typeof window !== "undefined" && window.Pylon) {
    window.Pylon("showChatBubble");
  }
}

export function hidePylonBubble() {
  if (typeof window !== "undefined" && window.Pylon) {
    window.Pylon("hideChatBubble");
  }
}

export function showPylonChat() {
  if (typeof window !== "undefined" && window.Pylon) {
    window.Pylon("show");
  }
}

export function hidePylonChat() {
  if (typeof window !== "undefined" && window.Pylon) {
    window.Pylon("hide");
  }
}

/* This ensures we always have the bubble hidden on the editor */
export function setupPylonEditorHideEvent() {
  if (typeof window !== "undefined") {
    let attempts = 0;

    const intervalId = setInterval(() => {
      attempts++;

      if (window.Pylon && attempts <= 50) {
        window.Pylon("onHide", function () {
          if (window.location.pathname.includes("/editor")) {
            window.Pylon("hideChatBubble");
          }
        });
      } else {
        clearInterval(intervalId);
      }
    }, 500);
  }
}
