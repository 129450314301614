import { cssVar, defineStyleConfig } from "@chakra-ui/react";

const $arrowBg = cssVar("popper-arrow-bg");

const baseStyle = {
  borderRadius: "0.75rem",
  color: "gray.0",
  bg: "blue.800",
  p: "0.25rem 0.5rem",
  [$arrowBg.variable]: "colors.blue.800",
  fontSize: "0.6875rem",
  lineHeight: "1.25rem",
  fontWeight: 400,
  whiteSpace: "pre-line",
  letterSpacing: "0.01rem",
};

export const tooltipTheme = defineStyleConfig({ baseStyle });
