"use client";

import theme from "@/config/theme";
import { EnvService } from "@/services/Environment/Environment";
import { UserProvider } from "@auth0/nextjs-auth0/client";
import { ChakraProvider } from "@chakra-ui/react";
import { usePathname, useSearchParams } from "next/navigation";
import { useEffect, useMemo, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { CacheProvider } from "@chakra-ui/next-js";
import { ToastContainer } from "react-toastify";
import { ANALYTICS_EVENTS, analytics_track } from "@/utils/mixpanel";
import { CustomerToolsInjectorAux } from "@/utils/customerTools";
import { ReactQueryDevtools } from "react-query/devtools";
import { AxiosError } from "axios";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error: unknown) => {
        if (error instanceof AxiosError && error?.response?.status === 429) {
          return true;
        }
        return failureCount < 3;
      },
      retryDelay: (attemptIndex, error: unknown) => {
        if (error instanceof AxiosError && error?.response?.status === 429) {
          const retryAfter = error.response?.headers["retry-after"];

          if (retryAfter) {
            const retryAfterDate = new Date(retryAfter).getTime();
            if (!isNaN(retryAfterDate)) {
              return retryAfterDate - Date.now();
            }

            return parseInt(retryAfter) * 1000;
          }
        }

        return Math.min(1000 * Math.pow(2, attemptIndex), 30000);
      },
    },
  },
});

export default function Providers({ children }: { children: React.ReactNode }) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [isEnvReady, setIsEnvReady] = useState<boolean>(false);
  const isEditor = useMemo(
    () => Boolean(pathname?.includes("/editor/")),
    [pathname]
  );

  useEffect(() => {
    EnvService.init().then(() => {
      setIsEnvReady(true);
    });
  }, []);

  // Track page views
  useEffect(() => {
    const url = `${pathname}?${searchParams}`;
    analytics_track(ANALYTICS_EVENTS.PAGE_VIEW, { url });
  }, [pathname, searchParams]);

  return (
    <QueryClientProvider client={queryClient}>
      <CacheProvider>
        <ChakraProvider theme={theme} disableEnvironment>
          <UserProvider>
            <CustomerToolsInjectorAux isEnvReady={isEnvReady} />

            <ToastContainer
              position={isEditor ? "bottom-center" : "top-right"}
              icon={false}
              hideProgressBar
              closeButton={false}
              bodyClassName="custom-toast"
            />
            {children}
          </UserProvider>
        </ChakraProvider>
      </CacheProvider>

      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
