import { tabsAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

/* Changeing  */
export const tabsTheme = defineMultiStyleConfig({
  baseStyle: {},
  variants: {
    line: {
      tab: {
        borderRadius: "0.5rem",
        padding: "0.25rem 1rem",
        color: "gray.500",
        bgColor: "gray.0",
        border: "unset",
        fontSize: "0.8125rem",
        lineHeight: "1.375rem",
        fontWeight: 600,
        whiteSpace: "pre-line",
        letterSpacing: "0.01rem",
        noOfLines: 1,
        textOverflow: "ellipsis",
        background: "none",
        _selected: {
          color: "blue.950",
          bgColor: "blue.100",
        },
        _hover: {
          color: "blue.950",
          bgColor: "blue.50",
          _selected: {
            bgColor: "blue.100",
          },
        },
        _active: {
          color: "blue.950",
          bgColor: "blue.100",
        },
        _disabled: {
          cursor: "not-allowed",
          color: "gray.300",
        },
      },
      tablist: {
        borderBottom: "unset",
        gap: "0.25rem",
      },
    },
  },
});
