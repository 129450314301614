import { EnvService } from "@/services/Environment/Environment";
export const SESSION_REWIND_TOKEN = "FoFplwjBVI91ZJdtC7YiW4sQyB0euTNF4ENIpwdc";

// Typescript global window type
declare global {
  interface Window {
    sessionRewind: any;
    SessionRewindConfig: any;
  }
}

const config = (userEmail: string, userName: string) => {
  return {
    apiKey: SESSION_REWIND_TOKEN,
    startRecording: false,
    onLoad: () => {
      window.sessionRewind.stopSession();

      window.sessionRewind.identifyUser({
        userId: userEmail,
        userName: userName,
        isInternal: false,
      });

      window.sessionRewind.startSession();
    },
  };
};

export const launchSessionReview = (userEmail: string, userName: string) => {
  const environment = EnvService.getVar("DEPLOYMENT_ENV");

  const BLACKLIST_EMAILS = [
    "ribeiralves@gmail.com",
    "pedroallenrevez@gmail.com",
  ];

  //If dev env or internal user
  if (
    environment !== "prod" ||
    userEmail.includes("@spot-technologies.com") ||
    userEmail.includes("@noxus.ai") ||
    BLACKLIST_EMAILS.includes(userEmail)
  ) {
    return;
  }

  var w = window;
  w.SessionRewindConfig = config(userEmail, userName);
  var f = document.createElement("script");
  (f.async = true),
    (f.crossOrigin = "anonymous"),
    (f.src = "https://rec.sessionrewind.com/srloader.js");

  var g = document.getElementsByTagName("head")[0];
  g.insertBefore(f, g.firstChild);
};
