"use client";

import apiClient from "@/services/api-client";

let cache: Record<string, string> = {};

const fetchVars = (): Promise<Record<string, string>> => {
  return apiClient.get(`/api/environment`).then((response) => {
    return response.data;
  });
};

const getVar = (name: string) => {
  return cache[name];
};

const init = async () => {
  const result = await fetchVars();
  cache = { ...cache, ...result };

  return cache;
};

const EnvService = {
  init,
  getVar,
};

export { EnvService };
