import { checkboxAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const small = defineStyle({
  icon: {
    fontSize: "0.5rem",
  },
});

const baseStyle = definePartsStyle({
  control: {
    bg: "gray.0",
    border: "1px solid",
    borderColor: "gray.600",
    borderRadius: "0.25rem",
    height: "1rem",
    width: "1rem",
    outline: "unset",
    _checked: {
      bg: "blue.900",
      borderColor: "blue.900",
      _hover: {
        bgColor: "blue.900",
        borderColor: "blue.900",
      },
    },
    _hover: {
      bgColor: "blue.50",
      borderColor: "blue.700",
    },
    _disabled: {
      bg: "gray.50",
      borderColor: "gray.300",
      cursor: "not-allowed",
    },
  },
  label: {
    width: "100%",
  },
});

export const checkboxTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { small },
  defaultProps: {
    variant: "small",
  },
});
